<!--
 * @Author: your name
 * @Date: 2020-12-14 15:41:00
 * @LastEditTime: 2021-12-09 19:38:15
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /ksh-factory-vue/src/bizComponents/AccountLiveTransferModal.vue
-->
<template>
  <div>
    <el-dialog
      title="视频采购费转账"
      width="35%"
      :visible.sync="visible"
      :show-close="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      destroy-on-close
    >
      <el-row type="flex" justify="space-between" class="desc-text">
        <span>组织名称：</span>
        <span class="flex-box align-right">{{ item.orgName || '-' }}</span>
      </el-row>
      <el-row
        type="flex"
        justify="space-between"
        align="middle"
        class="desc-text"
      >
        <span>负责人：</span>
        <span class="flex-box align-right">{{ item.name || '-' }}</span>
      </el-row>
      <el-row
        type="flex"
        justify="space-between"
        align="middle"
        class="desc-text"
      >
        <span>帐号：</span>
        <span class="flex-box align-right">{{ item.username || '-' }}</span>
      </el-row>
      <!-- <el-row type='flex' justify="space-between" align="middle" class="desc-text">
        <span>剩余直播服务费：</span>
        <span class="flex-box align-right">{{item.lessCode|| '-'}}</span>
      </el-row> -->
      <el-divider class="w-h" />
      <el-row
        type="flex"
        justify="space-between"
        align="middle"
        class="desc-text"
      >
        <span>当前视频采购费余额：</span>
        <span class="flex-box align-right">{{ accountLiveSummaryData.disposableAmount || 0 }}</span>
      </el-row>
      <el-row
        type="flex"
        justify="space-between"
        align="middle"
        class="desc-text"
      >
        <span>请输入转账金额：</span>
        <el-input-number
          v-model="money"
          :precision="0"
          :max="accountLiveSummaryData.disposableAmount"
          :min="0"
          placeholder="请输入转账金额"
        />
      </el-row>
      <el-row slot="footer" type="flex" justify="center">
        <el-button plain @click="onClose">取消</el-button>
        <el-button type="primary" :loading="loading" @click="onSave">确认</el-button>
      </el-row>
    </el-dialog>
    <PaymentCaptchaModal
      :visible="showCodeVisible"
      code-type="4"
      :check-value="money"
      @onSuccess="onSuccess"
      @onClose="closePaymentModal"
    />
    <CheckSmsModal
      :visible="showSmsVisible"
      :check-value="money"
      @onSuccess="onCheckSmsSuccess"
      @onClose="closePaymentModal"
    />
    <PaymentLimitModal
      :visible="showLimitVisible"
      :limit-type="limitType"
      @onClose="closePaymentModal"
    />
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import PaymentCaptchaModal from '@/bizComponents/PaymentCaptchaModal'
import PaymentLimitModal from '@/bizComponents/PaymentLimitModal'
import CheckSmsModal from '@/bizComponents/CheckSmsModal'
import {isMobile} from '@/utils/util.js'
export default {
  name: 'AccountLiveTransferModal',
  components: {
    PaymentLimitModal,
    PaymentCaptchaModal,
    CheckSmsModal
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      money: '',
      loading: false,
      showCodeVisible: false,
      showLimitVisible: false,
      checkValue: '', // 敏感操作 扫码成功返回值 需要放到买码接口
      isMobile: isMobile(),
      showSmsVisible: false,
      smsCheckValue: '',
      limitType: ''
    }
  },
  computed: {
    ...mapGetters(['accountLiveSummaryData', 'factoryId'])
  },
  watch: {
    visible(newVal) {
      if (newVal) {
        this.money = ''
        this.getAccountLiveSummary()
      }
    }
  },
  methods: {
    ...mapActions(['onTransferAccountLive', 'getAccountLiveSummary']),
    onValid() {
      let valid = true
      if (!this.money) {
        valid = false
        this.$message.error('请输入转账积分')
      }
      return valid
    },
    onSave() {
      if (!this.onValid()) return
      this.loading = true
      const req = {
        toOrgId: this.item.orgId,
        amount: this.money,
        checkValue: this.checkValue,
        smsCheckValue: this.smsCheckValue
      }
      this.onTransferAccountLive(req).then(
        () => {
          this.loading = false
          this.onClose()
          this.$emit('onSubmit', [this.item.orgId, this.factoryId])
          this.$message.success('视频采购费转账成功')
          this.showSmsVisible = false
          this.checkValue = ''
          this.smsCheckValue = ''
        },
        rea => {
          this.loading = false
          this.checkValue = ''
          this.smsCheckValue = ''
          // 大于支付限额
          if (rea.code === 4003) {
            if (this.isMobile) {
              this.showSmsVisible = true
            } else {
            // 扫码
              this.showCodeVisible = true
            }
          } else if (rea.code === 4001 || rea.code === 4002) {
            // 提示
            this.limitType = rea.code === 4001 ? 'sms' : 'wechat'
            this.showLimitVisible = true
          } else {
            this.$message.error(rea.message)
          }
        }
      )
    },
    clear() {},
    onClose() {
      this.$emit('onClose')
    },
    closePaymentModal() {
      this.showCodeVisible = false
      this.showLimitVisible = false
    },
    onSuccess(ticket) {
      this.checkValue = ticket
      this.onSave()
    },
    onCheckSmsSuccess(sms) {
      this.smsCheckValue = sms
      this.checkValue = 'isMobile'
      this.onSave()
    }
  }
}
</script>
<style lang="scss" scoped>
.certify-image {
  height: 160px;
  width: 160px;
  background-color: #f6f7fb;
  margin-top: 10px;
}
.desc-text {
  color: #272e40;
  &:first-of-type {
    margin-top: 0;
  }
  + .desc-text {
    margin-top: 10px;
  }
}
.el-divider--horizontal {
  margin: 15px 0;
}
</style>
